::selection {
  color: #222222;
  background: #9bbc0f;
}

body {
  font-family: monospace, "Courier New", Courier;
  line-height: 1.8;
}

a {
  color: #9bbc0f;
  text-decoration: none;

  &:hover {
    color: #bee412;
    text-decoration: underline;
  }

  &:visited {
    color: #667a0a;
  }
}

hr {
  border-top: 1px solid #cccccc;
}

.i {
  line-height: 0;
}

pre {
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
}

ol {
  list-style-type: decimal-leading-zero;
}

#w {
  width: 800px;
}

h1 {
  &.with-tags {
    margin-bottom: 0;
  }
}

/* Retro Hit Counter Styles */
.hit-counter {
  img {
    height: 16px;
    vertical-align: middle;
  }
}

@media only screen and (max-width: 800px) {
  #w,
  img {
    width: 100%;
  }

  #w {
    max-width: 800px;
  }

  img {
    height: auto;
  }

  section {
    .footnotes {
      overflow-x: scroll;
    }
  }
}

@media (prefers-color-scheme: dark) {
  html {
    scrollbar-color: #9bbc0f #667a0a;
  }

  body {
    background: #222222;
    color: #eeeeee;
  }

  pre,
  blockquote {
    background: #111111;
    border: 10px solid #333333;
    max-width: 100%;
    width: auto;
    padding: 20px;
    margin: 0 auto;
  }

  hr {
    border-top: 2px solid #333333;
  }
}